$backLight: #FFFEF9;
$white: #fff;
$brand: #EE7C3B;
$dark: #14121F;
$lightGray: #D1D5DA;
$lightBrand: #FDF8F4;


$gray: #646464;
$lightYellow: #FFFAE6;
$midleYellow: #FFF5CF;
$Yellow: #FFE583;
$red: #CF2F2F;
$green: #007D14;
$blue: #021ba7;




.front__page__wrap{
    width: 100%;
    height: 100vh;
    overflow: hidden;

}
.content__wrap{
    position: relative;
    height: 94vh;
    display: flex;
    flex-direction: column;
    /* display: flex; */
   /*  flex: 1 1 1 100%; */
}
.content__container{
    position: relative;
    display: flex;
    height: 93%;
}
.container__front{
    padding: 0 20px;
}
.olMap{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 94vh;


}.ui-autocomplete {
    max-height: 300px;
    max-width: 345px;
    overflow-y: auto;

    overflow-x: hidden;
  }
  .footer{
     // position: absolute;
     width: fit-content;
     bottom: 0;
     margin-top: auto;
      background-color: $Yellow;
      color: $dark;
      z-index: 9999;
      /* left: 0;
      bottom: 0; */
      display: flex;
      align-items: center;
      font-size: 14px;
      //border-radius: 15px;
  }
  .footer__content{
      padding: 10px 15px;
      & a {
          text-decoration: none;
          color: $dark;
          &:hover{
              color: $gray;
          }
      }
  }
.mr__15{
    margin-right: 15px;
}