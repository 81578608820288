.top__nav {
    width: 100%;
    position: relative;
    background-color: $midleYellow;
}
.nav__wrap{
    width: 100%;
    height: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;


}
.menu{
    height: 100%;
    display: flex;
    align-items: center;
    & a {
        text-decoration: none;
    }
}
.menu__login{
    display: none;
}
.tabs__link{
    color: $dark;
    margin-left: 20px;
    padding: 2px;
    transition: all 0.2s ease 0s;
    &:hover{
        color: $gray;
        border-bottom: 2px solid $gray;
        transition: all 0.2s ease 0s;
    }
}

.nav__link{
    color: $dark;
    margin-left: 20px;
    padding: 2px;
    transition: all 0.2s ease 0s;
    &:hover{
        color: $gray;
        border-bottom: 2px solid $gray;
        transition: all 0.2s ease 0s;
    }
}
.btn__top__nav{

    background-color: $Yellow;
    &:hover{
        background-color: darken($Yellow,15%);
    }
}
.phone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark;
    font-size: 18px;
    & sapn {
        font-weight: 700;
    }
    & i {
        margin-right: 7px;
    }

}
.logo__link{
    margin-left: 20px;
    text-decoration: none !important;
    font-size: 20px;
    font-weight: 700;
    color: $dark;
    &:hover{
        color: $dark;
    }
}
 //бургер
 .burger{
    display: none;
    width: 40px;
    height: 30px;
    cursor: pointer;
    //border: 1px solid black;
    position: relative;
}
.burger__line{
    display: block;
    width: 100%;
    height: 4px;
    background-color: $dark;
    position: absolute;
    left: 0;
    transition: .3s all linear;
    border-radius: 20px;
}
.burger__line__first{
top: 0;
}
.burger__line__second, .burger__line__third{
top: 50%;
transform: translateY(-50%);
}

.burger__line__fourth{
bottom: 0;
}
.burger__active{

    & .burger__line__first, .burger__line__fourth{
        opacity: 0;
    }
    & .burger__line__second{
        transform: translateY(-50%) rotate(45deg);
    }
    & .burger__line__third{
        transform: translateY(-50%) rotate(-45deg);
    }
}
.tabs__link__active{
    color: $gray;
    border-bottom: 2px solid $gray;
}
.ol-zoom{
    display: none;
}
.menu__block__wrapp{
    margin-left: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: left;
}
.dropdown{
    margin-left: 15px;
}