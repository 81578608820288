.order__form__wrap {
    /* position: relative;
    left: 0;
    top: 0; */

    width: 50%;
    min-width: 450px;
    height: 100%;
    display: flex;
    /* align-items: center; */
    padding: 15px 15px 15px 0;
    /* margin-left: 20px; */





}

.order__form__box {
    background-color: $midleYellow;
    padding: 13px 20px 11px 20px;
    margin-left: 20px;
    border-radius: 15px;
    width: 100%;
    //position: absolute;
    z-index: 2;
    //left: 0;
    box-shadow: 0 15px 50px 0 rgba(84, 84, 120, 0.25);
    //height: 84vh;
    overflow-x: auto;
}

/* .order__form__box::before {
    content: '';
    background: inherit;
    box-shadow: inset 0 0 0 400px rgba(255, 255, 255, 0.2);
    backdrop-filter: saturate(180%) blur(10px);
    border-radius: 15px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 91vh;


}

.order__form__box::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: $Yellow;
    opacity: .4;
    border-radius: 15px;
    height: 91vh;
    overflow-x: auto;
} */

.order__form__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    color: $gray;
}
.order__form__sity{
    display: flex;
    align-items: center;
    & select {
        color: $dark;
    }
    & span{
        margin-right: 8px;
        font-weight: 500;
    }
}

.order__form__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    i {
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 16px;
        text-align: center;
        line-height: 30px;

        margin-right: 4px;

        &:hover {
            color: $white;
            background-color: $dark;
            border-radius: 100%;
        }
    }
}

.order__form__select {
    background-color: $Yellow;
    border: 0;

    &:active {
        background-color: $Yellow;
        border: 0;
    }
}

.btn-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    height: 30px;
    padding: 0px 10px;
    color: $dark;
    border: 2px solid $dark;
    /* background-color: #EE7C3B; */
    border-radius: 10px;

    &:hover {
        color: $green;
        border-color: $green;
        /* background-color: lighten(#EE7C3B, 15%); */

    }
    & i{
        height: 25px;
        width: 25px;
        &:hover{
            background-color: transparent;
            color: $green;
        }
    }
}

.form__pass {
    width: 25%;
    margin-left: 20px;
}

.order__form__text {
    font-size: 12px;
    margin-top: 10px;
    color: $gray;
}

.form_input {
    width: 100%;
    border: 0;
    height: 30px;
    border-radius: 9px;
    padding-left: 10px;

    &:active {
        outline: 0 !important;
        outline-offset: 0 !important;
        border: 0 !important;
    }

    &:hover {
        outline: 0 !important;
        outline-offset: 0 !important;
        border: 0 !important;
    }

    &:focus {
        outline: 0 !important;
        outline-offset: 0 !important;
        border: 0 !important;
    }
}

.sortable__row {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    .input__icon {
        position: absolute;
        right: 7px;
        top: 50%;
        margin-top: -10px;
        font-size: 20px;

    }

    .input__icon__1 {
        color: green;
    }

    .input__icon__2 {
        color: $brand;
    }

    .input__icon__none {
        display: none;
    }
}

.sortable__item {
    width: 30px;
    height: 30px;
    background-color: $dark;
    border-radius: 9px 0 0 9px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & i {
        font-size: 16px;
    }
}

.car__options__row {
    margin-top: 20px;
}

.car__options__btn {
    cursor: pointer;
    width: 100%;
    height: 30px;

    border-radius: 9px;
   /*  line-height: 30px; */
    text-align: left;
    margin-bottom: 20px;
    padding: 1px 0;

    &:hover {
        /* background-color: $Yellow; */
        /* background-color: $lightGray; */
        border: 2px solid $dark;
    }
    & span{
        margin-left: 5px;
        font-weight: 700;
        color: $dark;
    }
    & i {
        font-size: 16px;
        padding-left: 10px;
       /*  line-height: 30px; */

    }
}

.car__options__btn__active {
    /* background-color: $lightGray; */
    border: 2px solid $dark;
}

.form_input__sortable {
    border-radius: 0 9px 9px 0;
    padding-left: 15px;

}

.car__options__list {
    padding-top: 0px;
    display: none;
    padding-left: 30px;


    & li {
        list-style-type: none;
        border-left: 2px soborlid $gray;
        padding-left: 10px;
    }
}

.car__options__list__active {
    display: block;
}

.order_psw {
    width: 50%;
    margin-left: 15px;
}

.btn-order_psw {
    color: $dark;
    width: 50%;
    height: 30px;
/*     background-color: #EE7C3B; */

    padding: 2px 0;
    text-align: center;
    margin-right: 15px;
    border-radius: 9px;
    border: 2px solid $dark;
    /* background-color: #EE7C3B; */
    border-radius: 10px;



    &:hover {
       /*  color: $white;
        background-color: lighten(#EE7C3B, 15%); */
        color: $green;
        border-color: $green;
    }
}

.btn-order__submit {
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 9px;
    color: $white;
    background-color: $dark;
    padding: 7px 46px;
    font-weight: 700;

    &:hover {
        color: $white;
        background-color: $green;

    }
}

.arrival__click__error {
    color: $white;
    padding: 2px 12px;
    background-color: $red;
    display: none;
}

.arrival__click__error-active {
    display: block;
}

.show__price {

    display: flex;
    justify-content: end;
    align-items: center;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    //background-color: $dark;
    color: $white;


    padding: 0px;
    //font-size: 18px;
    //margin-right: -35px;
    border-radius: 9px;

}
.show__price__wrap{
    display: flex;
    background-color: $dark;
    align-items: center;
    height: 40px;
    border-radius: 9px;

}
.show__price__dist{
    padding: 0px 15px;
    border-right: 1px solid $white;
}
.show__price__rur{
    font-size: 18px;

    padding: 0 15px;
}

.car__options__header{
    font-weight: 700;
    color: $dark;
    & i {
        font-size: 18px;
        margin: 0;
        &:hover{
            background-color:  transparent;
            color: $dark;

        }
    }
}

.order__form__title__gr{
    color: $green;
}
.form_input__gr{
    color: $green;
}

.car__options__time{
    width: 50%;
    /* margin-left: 15px; */
    max-width: 210px;
    position: relative;
    & i{
        position: absolute;
        right: 0;
        top: 0;
        &:hover{
            background-color: transparent;
            color: $dark;
            cursor: auto;
        }
    }
}
.input__mw50{
    max-width: 210px;
}
#btn__getpsw{
    margin-left: auto;
    margin-right: auto;
}
#reload__captcha__form{
    background-color: $dark;
    padding: 3px;
    &:hover{
        background-color: $gray;
    }
    i{
        //background-color: green;
        color: $white;
        &:hover{
            background-color: transparent;
        }
    }
}

.error__text-block{
    display: block;
}
#error__text__captcha__form{
    //display: none;
    & div{
        margin-left: auto;

    }
}
.input__invalid{
    border: 2px solid $red !important;
    &:hover{
        border: 2px solid $red !important;
    }
    &:active{
        border: 2px solid $red !important;
    }
}
.comments__wrap{
    width: 100%;
    & textarea{
        border-radius: 9px;
    }

}
.comments__header{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 700;
    & i {
        font-size: 18px;
        margin: 0;
        &:hover{
            background-color: transparent;
            color: $dark;
        }
    }
}
.text__err{
    width: 100%;
    padding: 10px;
    text-align: right;
    border-radius: 9px;

}
.block__green{
    background-color: $green;
    color: $white;

}
.block__red{
    background-color: $red;
    color: $white;

}
.success__order{
    background-color: $green;
    color: $white !important;
    border-radius: 7px;
    padding: 5px;
}
hr{
    margin: 10px 0px;
}
.check__wrapp{
    position: relative;
}

.check__header{
    font-weight: 700;
}

  .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $dark!important;
    border-color: $dark;
  }
.check__text{
    display: none;
    padding-left: 36px;
    margin-top: 5px;
    color: $gray;
    & a{
        transition: .5s;
        color: $dark;
        text-decoration: underline;
        &:hover{
            color: $green;
        }
    }
}
.check__text.active{
    display: block;
}
.cashless__list{
    display: none;
    padding-top: 10px;
}
.cashless__list.active{
    display: block;
}