.popup__form{
     position: absolute;
     z-index: 9;
     top: 0;
     left: 0;
     background-color: #00020580;
     width: 100%;
     height: 100vh;
     display: flex;
     justify-content: center;
     display: none;
 }
 .popup__form__box{
     margin-top: 11%;
     width: 400px;
     margin-left: auto;
     margin-right: auto;
     & form{
         background-color: $Yellow;
         padding: 20px;
         border-radius: 9px;
     }

 }
 .popup__form__row{
     width: 100%;

     justify-content: space-between;
     align-items: center;
     margin-bottom: 10px;
    & .form-check{
         margin-top: 5px;
    }
 }
 .popup__form__row-flex{
     display: flex;

 }
 .popup__btn__primary{
     border-radius: 9px;
     color: $white;
     background-color: $dark;
     padding: 6px 30px;
     margin-left: auto;
     margin-right: auto;

     &:hover{
         color: $white;
         background-color: #FF9900;

     }
 }
 .popup__form__header{
     display: flex;
     justify-content: space-between;
     font-size: 18px;
     margin-bottom: 5px;
          & i{
          padding: 10px;
          border-radius: 15px;

          &:hover{
               color: $gray;
          }

          }
          & span{
          font-size: 24px;
          font-weight: 700;
          }

 }
 .popup__active{
     display: block;
 }
 #popup__reg{
     & form{
         min-width: 550px;
     }

 }
 .captcha__form__row{
     margin-top: 5px;
 }
 .captcha__img{
     margin-right: 15px;

 }
 .captcha__wrap{
 display: flex;
 align-items: center;
 margin-bottom: 10px;
 padding-left: auto;
 }
 .btn-captcha{
     background-color: $white;
     margin-right: 5px;
     &:hover{
         background-color: $lightYellow;
     }
 }
 .error__text-block{
     display: none;
 }
 .getpass__btn{
      color: $dark;
      margin: 0;
      padding: 0;
      margin-left: auto;
      text-decoration: underline;
      &:hover{
           text-decoration: none;
           color: $gray;
      }
 }
 .popup__input{
width: 218px;
 }

 .popup__form__row-captcha{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
 }
 .el__none{
    display: none;
}
#error__text__captcha{
    margin-bottom: 5px;
}