.front__content__wrap{
    //display: none;
    width: 100%;

    //padding: 0 15px;
    //height: 94vh;
    //margin-left: 15px;
  /*  min-height: 100%; */
    /*  padding-right: 20px; */
    /*  position: relative; */
      overflow-y: auto;

}
.content__container.page__active{

    .content__page__menu{
        position: relative;
        z-index: 2;
    }

}

.content__page__menu{
  /*  width: 98%; */
   margin: 0 15px;
   /*  position: absolute; */
   // z-index: 2;
  //  right: 20px;
    top: 0;
    right: 0;
}

.pjax-container{
    //height: 84vh;
    width: 100%;
    background-color: $midleYellow;

    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    box-shadow: 0 15px 50px 0 rgba(84,84,120,0.25);
    top: 0;
    transition: all 0.4s ease 0s;

}

/* .pjax-container::before{
    content: '';
    margin-top: 15px;
    background: inherit;
    box-shadow: inset 0 0 0 400px rgba(255, 255, 255, 0.2);
    backdrop-filter: saturate(180%) blur(10px);
    border-radius: 15px;
    width: 100%;
    height: 91vh;
    position: absolute;
    z-index: -2;
    top: 0;
}
.pjax-container::after{
    content: '';
    margin-top: 15px;
    width: 100%;
    height: 91vh;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: $Yellow;
    opacity: .4;
    border-radius: 15px;
    overflow-x: auto;
} */

.pjax__wrap{
    padding: 15px 5% 15px 20px;
}
.pages__body__wrap{
    min-width: 700px;
}
.page__title{
    font-size: 24px;
    font-weight: 700;
    width: 90%;
}
.show__page__content{
    padding: 15px 20px;
}
.close__page{
    display: flex;
    justify-content: right;


    i {
        padding: 7px;
        font-size: 22px;
        &:hover{
            color: $gray;
        }
    }
}
.title__wrapp{
    display: flex;
    justify-content: space-between;
    align-items: center;
}