@charset "UTF-8";
.front__page__wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.content__wrap {
  position: relative;
  height: 94vh;
  display: flex;
  flex-direction: column;
  /* display: flex; */
  /*  flex: 1 1 1 100%; */
}

.content__container {
  position: relative;
  display: flex;
  height: 93%;
}

.container__front {
  padding: 0 20px;
}

.olMap {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 94vh;
}

.ui-autocomplete {
  max-height: 300px;
  max-width: 345px;
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  width: fit-content;
  bottom: 0;
  margin-top: auto;
  background-color: #FFE583;
  color: #14121F;
  z-index: 9999;
  /* left: 0;
  bottom: 0; */
  display: flex;
  align-items: center;
  font-size: 14px;
}

.footer__content {
  padding: 10px 15px;
}
.footer__content a {
  text-decoration: none;
  color: #14121F;
}
.footer__content a:hover {
  color: #646464;
}

.mr__15 {
  margin-right: 15px;
}

.top__nav {
  width: 100%;
  position: relative;
  background-color: #FFF5CF;
}

.nav__wrap {
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  height: 100%;
  display: flex;
  align-items: center;
}
.menu a {
  text-decoration: none;
}

.menu__login {
  display: none;
}

.tabs__link {
  color: #14121F;
  margin-left: 20px;
  padding: 2px;
  transition: all 0.2s ease 0s;
}
.tabs__link:hover {
  color: #646464;
  border-bottom: 2px solid #646464;
  transition: all 0.2s ease 0s;
}

.nav__link {
  color: #14121F;
  margin-left: 20px;
  padding: 2px;
  transition: all 0.2s ease 0s;
}
.nav__link:hover {
  color: #646464;
  border-bottom: 2px solid #646464;
  transition: all 0.2s ease 0s;
}

.btn__top__nav {
  background-color: #FFE583;
}
.btn__top__nav:hover {
  background-color: #ffd537;
}

.phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #14121F;
  font-size: 18px;
}
.phone sapn {
  font-weight: 700;
}
.phone i {
  margin-right: 7px;
}

.logo__link {
  margin-left: 20px;
  text-decoration: none !important;
  font-size: 20px;
  font-weight: 700;
  color: #14121F;
}
.logo__link:hover {
  color: #14121F;
}

.burger {
  display: none;
  width: 40px;
  height: 30px;
  cursor: pointer;
  position: relative;
}

.burger__line {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #14121F;
  position: absolute;
  left: 0;
  transition: 0.3s all linear;
  border-radius: 20px;
}

.burger__line__first {
  top: 0;
}

.burger__line__second, .burger__line__third {
  top: 50%;
  transform: translateY(-50%);
}

.burger__line__fourth {
  bottom: 0;
}

.burger__active .burger__line__first, .burger__active .burger__line__fourth {
  opacity: 0;
}
.burger__active .burger__line__second {
  transform: translateY(-50%) rotate(45deg);
}
.burger__active .burger__line__third {
  transform: translateY(-50%) rotate(-45deg);
}

.tabs__link__active {
  color: #646464;
  border-bottom: 2px solid #646464;
}

.ol-zoom {
  display: none;
}

.menu__block__wrapp {
  margin-left: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: left;
}

.dropdown {
  margin-left: 15px;
}

.order__form__wrap {
  /* position: relative;
  left: 0;
  top: 0; */
  width: 50%;
  min-width: 450px;
  height: 100%;
  display: flex;
  /* align-items: center; */
  padding: 15px 15px 15px 0;
  /* margin-left: 20px; */
}

.order__form__box {
  background-color: #FFF5CF;
  padding: 13px 20px 11px 20px;
  margin-left: 20px;
  border-radius: 15px;
  width: 100%;
  z-index: 2;
  box-shadow: 0 15px 50px 0 rgba(84, 84, 120, 0.25);
  overflow-x: auto;
}

/* .order__form__box::before {
    content: '';
    background: inherit;
    box-shadow: inset 0 0 0 400px rgba(255, 255, 255, 0.2);
    backdrop-filter: saturate(180%) blur(10px);
    border-radius: 15px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 91vh;


}

.order__form__box::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: $Yellow;
    opacity: .4;
    border-radius: 15px;
    height: 91vh;
    overflow-x: auto;
} */
.order__form__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #646464;
}

.order__form__sity {
  display: flex;
  align-items: center;
}
.order__form__sity select {
  color: #14121F;
}
.order__form__sity span {
  margin-right: 8px;
  font-weight: 500;
}

.order__form__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.order__form__row i {
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  margin-right: 4px;
}
.order__form__row i:hover {
  color: #fff;
  background-color: #14121F;
  border-radius: 100%;
}

.order__form__select {
  background-color: #FFE583;
  border: 0;
}
.order__form__select:active {
  background-color: #FFE583;
  border: 0;
}

.btn-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 30px;
  padding: 0px 10px;
  color: #14121F;
  border: 2px solid #14121F;
  /* background-color: #EE7C3B; */
  border-radius: 10px;
}
.btn-order:hover {
  color: #007D14;
  border-color: #007D14;
  /* background-color: lighten(#EE7C3B, 15%); */
}
.btn-order i {
  height: 25px;
  width: 25px;
}
.btn-order i:hover {
  background-color: transparent;
  color: #007D14;
}

.form__pass {
  width: 25%;
  margin-left: 20px;
}

.order__form__text {
  font-size: 12px;
  margin-top: 10px;
  color: #646464;
}

.form_input {
  width: 100%;
  border: 0;
  height: 30px;
  border-radius: 9px;
  padding-left: 10px;
}
.form_input:active {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 0 !important;
}
.form_input:hover {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 0 !important;
}
.form_input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 0 !important;
}

.sortable__row {
  position: relative;
  display: flex;
  margin-bottom: 10px;
}
.sortable__row .input__icon {
  position: absolute;
  right: 7px;
  top: 50%;
  margin-top: -10px;
  font-size: 20px;
}
.sortable__row .input__icon__1 {
  color: green;
}
.sortable__row .input__icon__2 {
  color: #EE7C3B;
}
.sortable__row .input__icon__none {
  display: none;
}

.sortable__item {
  width: 30px;
  height: 30px;
  background-color: #14121F;
  border-radius: 9px 0 0 9px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sortable__item i {
  font-size: 16px;
}

.car__options__row {
  margin-top: 20px;
}

.car__options__btn {
  cursor: pointer;
  width: 100%;
  height: 30px;
  border-radius: 9px;
  /*  line-height: 30px; */
  text-align: left;
  margin-bottom: 20px;
  padding: 1px 0;
}
.car__options__btn:hover {
  /* background-color: $Yellow; */
  /* background-color: $lightGray; */
  border: 2px solid #14121F;
}
.car__options__btn span {
  margin-left: 5px;
  font-weight: 700;
  color: #14121F;
}
.car__options__btn i {
  font-size: 16px;
  padding-left: 10px;
  /*  line-height: 30px; */
}

.car__options__btn__active {
  /* background-color: $lightGray; */
  border: 2px solid #14121F;
}

.form_input__sortable {
  border-radius: 0 9px 9px 0;
  padding-left: 15px;
}

.car__options__list {
  padding-top: 0px;
  display: none;
  padding-left: 30px;
}
.car__options__list li {
  list-style-type: none;
  border-left: 2px soborlid #646464;
  padding-left: 10px;
}

.car__options__list__active {
  display: block;
}

.order_psw {
  width: 50%;
  margin-left: 15px;
}

.btn-order_psw {
  color: #14121F;
  width: 50%;
  height: 30px;
  /*     background-color: #EE7C3B; */
  padding: 2px 0;
  text-align: center;
  margin-right: 15px;
  border-radius: 9px;
  border: 2px solid #14121F;
  /* background-color: #EE7C3B; */
  border-radius: 10px;
}
.btn-order_psw:hover {
  /*  color: $white;
   background-color: lighten(#EE7C3B, 15%); */
  color: #007D14;
  border-color: #007D14;
}

.btn-order__submit {
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 9px;
  color: #fff;
  background-color: #14121F;
  padding: 7px 46px;
  font-weight: 700;
}
.btn-order__submit:hover {
  color: #fff;
  background-color: #007D14;
}

.arrival__click__error {
  color: #fff;
  padding: 2px 12px;
  background-color: #CF2F2F;
  display: none;
}

.arrival__click__error-active {
  display: block;
}

.show__price {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
  padding: 0px;
  border-radius: 9px;
}

.show__price__wrap {
  display: flex;
  background-color: #14121F;
  align-items: center;
  height: 40px;
  border-radius: 9px;
}

.show__price__dist {
  padding: 0px 15px;
  border-right: 1px solid #fff;
}

.show__price__rur {
  font-size: 18px;
  padding: 0 15px;
}

.car__options__header {
  font-weight: 700;
  color: #14121F;
}
.car__options__header i {
  font-size: 18px;
  margin: 0;
}
.car__options__header i:hover {
  background-color: transparent;
  color: #14121F;
}

.order__form__title__gr {
  color: #007D14;
}

.form_input__gr {
  color: #007D14;
}

.car__options__time {
  width: 50%;
  /* margin-left: 15px; */
  max-width: 210px;
  position: relative;
}
.car__options__time i {
  position: absolute;
  right: 0;
  top: 0;
}
.car__options__time i:hover {
  background-color: transparent;
  color: #14121F;
  cursor: auto;
}

.input__mw50 {
  max-width: 210px;
}

#btn__getpsw {
  margin-left: auto;
  margin-right: auto;
}

#reload__captcha__form {
  background-color: #14121F;
  padding: 3px;
}
#reload__captcha__form:hover {
  background-color: #646464;
}
#reload__captcha__form i {
  color: #fff;
}
#reload__captcha__form i:hover {
  background-color: transparent;
}

.error__text-block {
  display: block;
}

#error__text__captcha__form div {
  margin-left: auto;
}

.input__invalid {
  border: 2px solid #CF2F2F !important;
}
.input__invalid:hover {
  border: 2px solid #CF2F2F !important;
}
.input__invalid:active {
  border: 2px solid #CF2F2F !important;
}

.comments__wrap {
  width: 100%;
}
.comments__wrap textarea {
  border-radius: 9px;
}

.comments__header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 700;
}
.comments__header i {
  font-size: 18px;
  margin: 0;
}
.comments__header i:hover {
  background-color: transparent;
  color: #14121F;
}

.text__err {
  width: 100%;
  padding: 10px;
  text-align: right;
  border-radius: 9px;
}

.block__green {
  background-color: #007D14;
  color: #fff;
}

.block__red {
  background-color: #CF2F2F;
  color: #fff;
}

.success__order {
  background-color: #007D14;
  color: #fff !important;
  border-radius: 7px;
  padding: 5px;
}

hr {
  margin: 10px 0px;
}

.check__wrapp {
  position: relative;
}

.check__header {
  font-weight: 700;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #14121F !important;
  border-color: #14121F;
}

.check__text {
  display: none;
  padding-left: 36px;
  margin-top: 5px;
  color: #646464;
}
.check__text a {
  transition: 0.5s;
  color: #14121F;
  text-decoration: underline;
}
.check__text a:hover {
  color: #007D14;
}

.check__text.active {
  display: block;
}

.cashless__list {
  display: none;
  padding-top: 10px;
}

.cashless__list.active {
  display: block;
}

.front__content__wrap {
  width: 100%;
  /*  min-height: 100%; */
  /*  padding-right: 20px; */
  /*  position: relative; */
  overflow-y: auto;
}

.content__container.page__active .content__page__menu {
  position: relative;
  z-index: 2;
}

.content__page__menu {
  /*  width: 98%; */
  margin: 0 15px;
  /*  position: absolute; */
  top: 0;
  right: 0;
}

.pjax-container {
  width: 100%;
  background-color: #FFF5CF;
  border-radius: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 15px 50px 0 rgba(84, 84, 120, 0.25);
  top: 0;
  transition: all 0.4s ease 0s;
}

/* .pjax-container::before{
    content: '';
    margin-top: 15px;
    background: inherit;
    box-shadow: inset 0 0 0 400px rgba(255, 255, 255, 0.2);
    backdrop-filter: saturate(180%) blur(10px);
    border-radius: 15px;
    width: 100%;
    height: 91vh;
    position: absolute;
    z-index: -2;
    top: 0;
}
.pjax-container::after{
    content: '';
    margin-top: 15px;
    width: 100%;
    height: 91vh;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: $Yellow;
    opacity: .4;
    border-radius: 15px;
    overflow-x: auto;
} */
.pjax__wrap {
  padding: 15px 5% 15px 20px;
}

.pages__body__wrap {
  min-width: 700px;
}

.page__title {
  font-size: 24px;
  font-weight: 700;
  width: 90%;
}

.show__page__content {
  padding: 15px 20px;
}

.close__page {
  display: flex;
  justify-content: right;
}
.close__page i {
  padding: 7px;
  font-size: 22px;
}
.close__page i:hover {
  color: #646464;
}

.title__wrapp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup__form {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  background-color: rgba(0, 2, 5, 0.5019607843);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  display: none;
}

.popup__form__box {
  margin-top: 11%;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.popup__form__box form {
  background-color: #FFE583;
  padding: 20px;
  border-radius: 9px;
}

.popup__form__row {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.popup__form__row .form-check {
  margin-top: 5px;
}

.popup__form__row-flex {
  display: flex;
}

.popup__btn__primary {
  border-radius: 9px;
  color: #fff;
  background-color: #14121F;
  padding: 6px 30px;
  margin-left: auto;
  margin-right: auto;
}
.popup__btn__primary:hover {
  color: #fff;
  background-color: #FF9900;
}

.popup__form__header {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 5px;
}
.popup__form__header i {
  padding: 10px;
  border-radius: 15px;
}
.popup__form__header i:hover {
  color: #646464;
}
.popup__form__header span {
  font-size: 24px;
  font-weight: 700;
}

.popup__active {
  display: block;
}

#popup__reg form {
  min-width: 550px;
}

.captcha__form__row {
  margin-top: 5px;
}

.captcha__img {
  margin-right: 15px;
}

.captcha__wrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: auto;
}

.btn-captcha {
  background-color: #fff;
  margin-right: 5px;
}
.btn-captcha:hover {
  background-color: #FFFAE6;
}

.error__text-block {
  display: none;
}

.getpass__btn {
  color: #14121F;
  margin: 0;
  padding: 0;
  margin-left: auto;
  text-decoration: underline;
}
.getpass__btn:hover {
  text-decoration: none;
  color: #646464;
}

.popup__input {
  width: 218px;
}

.popup__form__row-captcha {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.el__none {
  display: none;
}

#error__text__captcha {
  margin-bottom: 5px;
}

input:focus {
  border: 1px solid #97b46c;
}

.autocomplete-wrap {
  position: relative;
  width: 100%;
}

.autocomplete-input {
  width: 100%;
}

.autocomplete-list {
  background-color: #fff;
  position: absolute;
  top: 100%;
  width: 100%;
  display: none;
  z-index: 9999;
  max-height: 400px;
  overflow-y: auto;
}

.autocomplete-wrap.active .autocomplete-list {
  display: block;
  border-radius: 0 0 3px 3px;
}

.autocomplete-item {
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.autocomplete-item:hover, .autocomplete-item.focused {
  background: #FFE583;
}

.autocomplete-wrap.active .autocomplete-input {
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid transparent;
}

.search__сiti {
  font-size: 13px;
  color: #646464;
  font-style: italic;
  padding-top: 0;
}

.history__content {
  display: flex;
  flex-wrap: wrap;
}

.history__content__row {
  display: flex;
}

.history__content__block {
  margin-right: 15px;
}

.history__content__title {
  font-weight: 600;
  margin-right: 10px;
}

.status__gray {
  color: #646464;
}

.status__blue {
  color: #021ba7;
}

.status__green {
  color: #007D14;
}

.status__red {
  color: #CF2F2F;
}

.btn__cancel {
  margin-left: auto;
  background-color: #FFE583;
}
.btn__cancel:hover {
  background-color: #ffd537;
}

@media (max-width: 616px) {
  .history__content {
    display: flex;
    flex-direction: column;
  }
  .history__content__block {
    margin-top: 10px;
  }
}
@media (max-width: 1024px) {
  .top__nav {
    position: fixed;
    z-index: 99;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .page__active .order__form__wrap {
    margin-left: -100%;
    display: none;
    /* height: max-content; */
  }
  .page__active .front__content__wrap {
    margin: 0;
    /* height: 100%; */
    margin-left: auto;
  }
  .content__container {
    height: min-content;
  }
  .order__form__wrap {
    padding: 4px 0 0 0;
  }
  .burger {
    display: block;
    z-index: 9999;
  }
  .menu__block__wrapp {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .menu {
    position: absolute;
    z-index: 9998;
    left: -100%;
    top: 0;
    box-shadow: -5px 0px 15px rgba(20, 18, 31, 0.2);
    background-color: #FFE583;
    flex-direction: column;
    align-items: flex-end;
    text-align: left;
    padding: 70px 10px 10px 10px;
    width: 250px;
    height: 100vh;
    transition: 0.3s all linear;
  }
  .menu .tabs__link {
    font-size: 18px;
    line-height: 35px;
    width: 100%;
    height: 40px;
  }
  .menu a {
    margin-bottom: 3px;
    border-radius: 8px;
  }
  .menu a:hover {
    background-color: #14121F;
    color: #fff;
    padding-right: 15px;
    border: 0;
  }
  .menu .btn__top__nav {
    margin-top: 15px;
    background-color: #14121F;
    color: #fff;
  }
  .menu__login {
    display: block;
  }
  .nav__menu__active {
    left: 0;
  }
  .tabs__link__active {
    background-color: #14121F;
    color: #fff;
    padding-right: 15px;
    border: 0;
  }
  .content__wrap {
    position: relative;
    margin-top: 45px;
    /*     height: 100%;
        min-height: 100%; */
  }
  .order__form__box {
    margin-bottom: 4px;
  }
  .show__page__content {
    min-height: 100%;
  }
  .front__content__wrap {
    /* position: absolute;
    top: 0;
    right: 0; */
    height: 100%;
    overflow: hidden;
    margin-right: -100%;
    width: 100%;
  }
  .pjax-container {
    box-shadow: none;
    background-color: #FFF5CF;
    height: 100%;
  }
  .pjax-container::before, .pjax-container::after {
    display: none;
  }
  .content__page__menu {
    padding-right: 15px;
    padding: 0;
    margin: 0;
    display: block;
  }
  /* .page__active{
       height: 100%;
  } */
  .show__page__content {
    /* height: 94vh; */
    display: block;
  }
  .footer {
    display: block !important;
  }
  /* .front__page__wrap{
       overflow: overlay;
  } */
}
@media (max-width: 768px) {
  #osm {
    display: none;
  }
  body {
    background-color: #FFFAE6;
  }
  .order__form__wrap {
    margin-left: auto;
    margin-right: auto;
  }
  .order__form__box {
    margin-left: 0;
  }
  .front__page__wrap {
    overflow-y: auto;
  }
  .footer {
    width: 100%;
    font-size: 12px;
  }
}
@media (max-width: 530px) {
  .order__form__wrap {
    min-width: 250px;
    width: 100%;
  }
  .logo__link {
    font-size: 16px;
    margin-left: 0;
  }
  .phone {
    font-size: 14px;
  }
  .order__form__box {
    font-size: 14px;
  }
  .order__form__box input {
    font-size: 14px;
  }
  .order__form__box select {
    font-size: 14px;
  }
  .order__form__title {
    font-size: 14px;
  }
  .order__form__sity {
    font-size: 14px;
  }
  .nav__wrap {
    min-height: 45px;
  }
}