@media (max-width: 1024px){
     .top__nav{
          position: fixed;
          z-index: 99;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     }
     .page__active{
          & .order__form__wrap{
               margin-left: -100%;
               display: none;
               /* height: max-content; */
          }
          & .front__content__wrap{
               margin: 0;
              // min-height: 100%;
              /* height: 100%; */
              margin-left: auto;
          }
     }
.content__container{
     height: min-content;
     }
.order__form__wrap{
          padding: 4px 0 0 0;
     }
.burger{
     display: block;
     z-index: 9999;
}
.menu__block__wrapp{
     margin-left: 0;
     display: flex;
     flex-direction: column;
     align-items: flex-start;
}
.menu{
     position: absolute;
     z-index: 9998;
     left: -100%;
     top: 0;
     box-shadow: -5px 0px 15px rgba(20, 18, 31, 0.20);     background-color: $Yellow;
     flex-direction: column;
     align-items: flex-end;
     text-align: left;
     padding: 70px 10px 10px 10px;
     width: 250px;
     height: 100vh;
     transition: .3s all linear;
     & .tabs__link{
          font-size: 18px;
          line-height: 35px;
          width: 100%;
          height: 40px;

     }
     & a{
          margin-bottom: 3px;
          border-radius: 8px;
     }
     & a:hover{
          background-color: $dark;
          color: $white;
          padding-right: 15px;
          border: 0;

     }
     & .btn__top__nav{
          margin-top: 15px;
          background-color: $dark;
          color: $white;

     }
}
.menu__login{
     display: block;
 }
.nav__menu__active{
left: 0;
}
.tabs__link__active{
     background-color: $dark;
          color: $white;
          padding-right: 15px;
          border: 0;
}
.content__wrap{
     position: relative;
    margin-top: 45px;
/*     height: 100%;
    min-height: 100%; */
}
.order__form__box{
     margin-bottom: 4px;
}
.show__page__content{
     min-height: 100%;
}
.front__content__wrap{
     /* position: absolute;
     top: 0;
     right: 0; */
     height: 100%;
     overflow: hidden;
     margin-right: -100%;
     width: 100%;



}
.pjax-container{
     box-shadow: none;
     background-color: $midleYellow;
     height: 100%;
     &::before, &::after{
          display: none;
        }
}
.content__page__menu{
     padding-right: 15px;
     padding: 0;
     margin: 0;
     display: block;
}
/* .page__active{
     height: 100%;
} */
.show__page__content{
     /* height: 94vh; */
     display: block;
}
.footer {
     display: block !important;
}
//----------
/* .front__page__wrap{
     overflow: overlay;
} */
}//@media (max-width: 1024px)
@media (max-width: 768px){
     #osm{
          display: none;
     }
     body {
          background-color: $lightYellow;
     }
     .order__form__wrap{
          margin-left: auto;
          margin-right: auto;
     }
     .order__form__box{
          margin-left: 0;
     }
     .front__page__wrap{
          overflow-y: auto;
     }
     .footer{
          width: 100%;
          font-size: 12px;
     }

}//@media (max-width: 768px)
@media (max-width: 530px){
     .order__form__wrap{
          min-width: 250px;
          width: 100%;
     }
     .logo__link{
          font-size: 16px;
          margin-left: 0;
     }
     .phone{
          font-size: 14px;
     }
     .order__form__box{

          font-size: 14px;
          & input {
               font-size: 14px;
          }
          & select {
               font-size: 14px;
          }
     }

     .order__form__title{
          font-size: 14px;
     }
     .order__form__sity{
          font-size: 14px;
     }
     .nav__wrap{
          min-height: 45px;
     }
}//@media (max-width: 530px)