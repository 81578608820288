.history__content{
     display: flex;
     flex-wrap: wrap;
}
.history__content__row{
     display: flex;
}
.history__content__block{
     margin-right: 15px;
}
.history__content__title{
     font-weight: 600;
     margin-right: 10px;
}
.status__gray{
     color: $gray;
}
.status__blue{
     color: $blue;
}
.status__green{
     color: $green;
}
.status__red{
     color: $red;
}
.btn__cancel{

     margin-left: auto;
    background-color: $Yellow;
    &:hover{
        background-color: darken($Yellow,15%);
    }
}
@media (max-width: 616px){
   .history__content{
      display: flex;
      flex-direction: column;
 }
 .history__content__block{
   margin-top: 10px;
 }
}