input:focus {
     border: 1px solid #97b46c;
   }

   .autocomplete-wrap {
     position: relative;
     width: 100%;
   }

   .autocomplete-input {
     width: 100%;
   }

   .autocomplete-list {
     background-color: $white;
     position: absolute;
     top: 100%;
     width: 100%;
     display: none;
     z-index: 9999;
     max-height: 400px;
     overflow-y: auto;

   }

   .autocomplete-wrap.active .autocomplete-list {
     display: block;
     border-radius: 0 0 3px 3px;
   }

   .autocomplete-item {
     padding: 8px 10px;
     font-size: 14px;
     cursor: pointer;
     transition: all .3s ease;
   }
   .autocomplete-item:hover, .autocomplete-item.focused {
     background: $Yellow;
   }

   .autocomplete-wrap.active .autocomplete-input {
     border-radius: 3px 3px 0 0;
     border-bottom: 1px solid transparent;
   }
   .search__сiti{
     font-size: 13px;
     color: $gray;
     font-style: italic;
     padding-top: 0;
   }